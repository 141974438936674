const loanStatuses = [
  "pending",
  "in_review",
  "current",
  "written_off",
  "declined",
  "withdrawn",
  "closed",
];

const trxStatuses = [
  //"Disbursement",
  "CardPayment",
  "Payment",
  "Reversal",
  "Refund",
  "Charges",
];

const arrayToObj = (arr) => {
  return arr.reduce((obj, x) => {
    obj[x] = x;
    return obj;
  }, {});
};

export default {
  data() {
    return { loanStatuses, trxStatuses };
  },
  filters: {
    makeTrxStatusAction(value) {
      const _trx_statuses = arrayToObj(trxStatuses);

      switch (value) {
        // case _trx_statuses.Disbursement:
        //   return "Disburse Funds";
        case _trx_statuses.Payment:
          return "Register Payment";
        case _trx_statuses.CardPayment:
          return "Process Card Payment";
        case _trx_statuses.Reversal:
          return "Reverse Payment";
        case _trx_statuses.Refund:
          return "Do Refund";
        case _trx_statuses.Charges:
          return "Apply Charges";
      }
    },
    setTrxStatusColor(value) {
      const _trx_statuses = arrayToObj(trxStatuses);
      switch (value) {
        // case _trx_statuses.Disbursement:
        //   return "primary";
        case _trx_statuses.Payment:
          return "cyan";
        case _trx_statuses.CardPayment:
          return "success";
        case _trx_statuses.Reversal:
          return "teal";
        case _trx_statuses.Refund:
          return "error";
        case _trx_statuses.Charges:
          return "success";
      }
    },
    makeLoanStatusAction(value) {
      const _statuses = arrayToObj(loanStatuses);
      switch (value) {
        case _statuses.pending:
          return "Request Actions";
        case _statuses.in_review:
          return "Review Loan";
        case _statuses.current:
          return "Approve Loan";
        case _statuses.written_off:
          return "Enter Collections";
        case _statuses.declined:
          return "Decline Loan";
        case _statuses.closed:
          return "Close Loan";
        default:
          return "";
      }
    },

    setLoanStatusColor(value) {
      const _statuses = arrayToObj(loanStatuses);
      switch (value) {
        case _statuses.in_review:
          return "teal";
        case _statuses.current:
          return "primary";
        case _statuses.written_off:
          return "error";
        case _statuses.declined:
          return "error";
        case _statuses.closed:
          return "success";
        default:
          return "";
      }
    },

    displayStatus(value) {
      const _statuses = arrayToObj(loanStatuses);
      switch (value) {
        case _statuses.pending:
          return "Pending";
        case _statuses.in_review:
          return "In Review";
        case _statuses.current:
          return "Current";
        case _statuses.written_off:
          return "Collections";
        case _statuses.declined:
          return "Declined";
        case _statuses.closed:
          return "Closed";
        case _statuses.withdrawn:
          return "Withdrawn";
      }
    },
  },

  methods: {},
};
